<template>
  <div id="newsStoryContent">
    <!-- picE530A2D8E9C188A543630CD6B0E0765E.jpg -->
    <img id="Image1" style="width: 440px; height: 330px;" class="rounded" src="@/assets/news-network/sneak-peek-at-the-lu-story.jpg">
    <p id="Text1">
      You can now watch a short preview of the LEGO® Universe story!
      <br><br>
      Players will see the full version when they first enter LEGO Universe. The story
      sets the scene for the game—including how the dark and destructive Maelstrom was
      unleashed!
      <br><br>
      Check out the preview in our new
      <router-link to="/media/section/141010/category/141100">Media section</router-link>!
      <br>
    </p>
  </div>
</template>
